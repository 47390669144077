import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../banner/carousel.css';
import { getConfigurableProps, logoData,
         LogoSection, quoteContentData } from '../banner/testimonials';
import * as styles from './testimonials.module.css';
import { colors } from '../colors';


//
// partner logos

export const PartnerLogos = () => {

    return (
        <div
          className={ `${styles.container} ${styles.containerLogos}` }
          style={{ backgroundColor: colors.lightestGrey }} >

          <div className={ styles.innerContainer } >
            
            <div className={ styles.logoContainer } >

              { LogoSection(logoData.partners) }

              <div
                className={ styles.logoSectionSectionSeparator }
                key={ Math.random() } />

              { LogoSection(logoData.programs) }

            </div>
            
          </div>
        </div>
    );
};


//
// carousel

const QuoteMarks = () => {
    return (
        <div
          className={ styles.quoteMarks }
          style={{ color: colors.green }} >
          “
        </div>
    );
};

const Quote = ({ contentData, style }) => {
    // expects style to contain alignItems for flex layout
    return (
        <div
          className={ styles.quote }
          style={ style } >

          <div
            className={ styles.text }
            style={{ color: colors.black }} >
            { contentData.quote }
          </div>

          <div
            className={ styles.source }
            style={{ color: colors.green }} >
            { contentData.source }
          </div>
        </div>
    );
};


const SmallQuote = ({ contentData }) => {
    return (
        <div
          className={ styles.smallQuote }
          key={ Math.random() } >

          <QuoteMarks />
          <Quote contentData={ contentData } />
          
        </div>
    );
};

export const TestimonialCarousel = () => {

    const quotes = quoteContentData.map((contentData) => SmallQuote({ contentData }));
    
    return (
        <div className={ `${styles.container} ${styles.containerTestimonials}` } >
          <div className={ styles.innerContainer } >
            <Carousel { ...getConfigurableProps() } >
              { quotes }
            </Carousel>
          </div>
        </div>
   );
};
