import React, { useEffect } from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { refreshSessionId } from '../utils/session_id';
import { Logos } from '../components/banner/testimonials';
import { hr } from '../components/brief/lines';
import * as buttonStyles from '../components/button.module.css';
import { colors } from '../components/colors';
import Layout from '../components/layout';
import { TestimonialCarousel } from '../components/public_home/testimonials';
import * as styles from './enterprise.module.css';

import { RenderHead } from "../components/head";
export const Head = () => RenderHead({ title: "Enterprise Services" });



const HorizontalRule = () => {
    return (
        <div className={ styles.hr } >
          { hr() }
        </div>
    );
};


//
// Component

const Enterprise = ({ location }) => {
    
    useEffect(() => { refreshSessionId(); }, []);
    
    return (
        <Layout
          location={ location }
          removeHorizontalPadding={ true } >                      

          <div className={ styles.container } >

            <div
              className={ styles.title }
              style={{ color: colors.green }} >
              Enterprise Services
            </div>

            <div className={ styles.list } >

              <div className={ styles.item } >
                
                <div className={ styles.head } >
                  Team or practice subscriptions
                </div>
                
                <div className={ styles.body } >
                  Group package for unlimited access to Member-only briefs and repository
                </div>
                
              </div>

              <div className={ styles.spacer } />

              <div className={ styles.item } >
                
                <div className={ styles.head } >
                  Systematic sensing on targeted topics
                </div>
                
                <div className={ styles.body } >
                  Curated signals feed or regular summaries of signals in target domains (e.g. to support external publications)
                </div>
                
              </div>

              <div className={ styles.spacer } />

              <div className={ styles.item } >
                
                <div className={ styles.head } >
                  Custom content and advisory
                </div>
                
                <div className={ styles.body } >
                  Custom briefs (e.g. deep-dive into a domain, ecosystem analysis), targeted advisory, leadership communications, etc.
                </div>
                
              </div>
              
            </div>

            <div className={ `${styles.section} ${styles.hr} ${styles.first}`} >
              { HorizontalRule() }
            </div>

            <div className={ `${styles.section} ${styles.bundle}` } >

              <div className={ styles.head } >
                Market shifts bundle
              </div>

              <div className={ styles.body } >
                6 Member seats + 5 hours of custom advisory for $5,000
              </div>
              
            </div>

            <div className={ `${styles.section} ${styles.hr} ${styles.second}`} >
              { HorizontalRule() }
            </div>

            <div className={ `${styles.section} ${styles.ready}` } >

              <div className={ styles.head } >
                Ready to make better and faster decisions?
              </div>

              <div className={ styles.body } >
                Reach out to discuss your needs or custom requirements.
              </div>
              
            </div>

            <div className={ `${styles.section} ${styles.pilot}` } >

              <div
                className={ styles.head }
                style={{ color: colors.green }} >
                Pilots start at $5K
              </div>

              <div
                className={ styles.body }
                style={{ color: colors.green }} >
                Reach out for a free sample
              </div>
              
            </div>

            <div className={ `${styles.section} ${styles.contact}` } >

              <OutboundLink
                className={ `${buttonStyles.submit} ${styles.button}` }
                href='mailto:business@6pages.com?subject=Enterprise%20services' >
                Contact Us
              </OutboundLink>
              
            </div>

            <div
              className={ `${styles.section} ${styles.testimonials}` }
              style={{ background: colors.lightestGrey }} >
              <TestimonialCarousel />
            </div>

            <div
              className={ `${styles.section} ${styles.logos}` }
              style={{ background: colors.lightestGrey }} >
              <Logos />
            </div>
            
          </div>
          
        </Layout>
    );
};

export default Enterprise;
