// extracted by mini-css-extract-plugin
export var container = "testimonials-module--container--zAV5M";
export var containerLogos = "testimonials-module--container-logos--AgDLo";
export var containerTestimonials = "testimonials-module--container-testimonials--W9Rxn";
export var innerContainer = "testimonials-module--inner-container--NpG3u";
export var logoContainer = "testimonials-module--logo-container--DcsIR";
export var logoSectionSectionSeparator = "testimonials-module--logo-section-section-separator---cTBT";
export var quote = "testimonials-module--quote--emCZo";
export var quoteMarks = "testimonials-module--quote-marks--Gh2b+";
export var smallQuote = "testimonials-module--small-quote--jj3Pb";
export var source = "testimonials-module--source--01G85";
export var text = "testimonials-module--text--s6cUr";